import achiever from './imgs/achiever.png';
import busy from './imgs/busy.png';
import curious from './imgs/curious.png';
import fighter from './imgs/fighter.png';
import myJourney from './imgs/myJourney.png';
import nobullshit from './imgs/nobullshit.png';
import optimiser from './imgs/optimiser.png';
import overwhelmed from './imgs/overwhelmed.png';
import protector from './imgs/protector.png';
import unlock from './imgs/unlock.png';
import yourJourney from './imgs/yourJourney.png';
import funcMed from './imgs/funcMed.png';
import caffeineNeverSleeps from './imgs/caffeineNeverSleeps.png';
import sleepDreamReap from './imgs/sleepDreamReap.png';
import orgFood from './imgs/orgFood.png';
import fruitJuice from './imgs/fruitJuice.png';

// ELEMENTS SELECTION

// Elements for sticky navigation
const header = document.querySelector('.header');
const heroBoxEl = document.querySelector('.hero-box');
// Elements for lazy loading images

// Mobile navigation menu
const btnNavEl = document.querySelector('.btn-mobile-nav');
const navContainerEl = document.querySelector('.nav-container');
const heroContainerEl = document.querySelector('.hero-container');

// STICKY NAVIGATION

const stickyNav = function (entries) {
  const [entry] = entries;

  if (!entry.isIntersecting) {
    navContainerEl.classList.add('sticky');
  } else {
    navContainerEl.classList.remove('sticky');
  }
};

const headerObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
});

headerObserver.observe(header);

// Lazy loading images
const lazyLoadImages = function () {
  const lazyImages = document.querySelectorAll('img[data-src]');

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const imageObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;

        if (lazyImage.dataset.src) {
          // Store imported HQ images in an object, so the link does not get broken
          const imagesObj = {
            'imgs/achiever.png': achiever,
            'imgs/busy.png': busy,
            'imgs/curious.png': curious,
            'imgs/fighter.png': fighter,
            'imgs/myJourney.png': myJourney,
            'imgs/nobullshit.png': nobullshit,
            'imgs/optimiser.png': optimiser,
            'imgs/overwhelmed.png': overwhelmed,
            'imgs/protector.png': protector,
            'imgs/unlock.png': unlock,
            'imgs/yourJourney.png': yourJourney,
            'imgs/funcMed.png': funcMed,
            'imgs/caffeineNeverSleeps.png': caffeineNeverSleeps,
            'imgs/sleepDreamReap.png': sleepDreamReap,
            'imgs/orgFood.png': orgFood,
            'imgs/fruitJuice.png': fruitJuice,
          };
          // Identifying data-src for each image
          const originalDataSrc = lazyImage.getAttribute('data-src');
          // Attributing the right image using the original data-src
          lazyImage.src = imagesObj[originalDataSrc];

          lazyImage.removeAttribute('data-src');
          lazyImage.classList.remove('lazy-img');
          observer.unobserve(lazyImage);
        }
      }
    });
  }, observerOptions);

  lazyImages.forEach(lazyImage => imageObserver.observe(lazyImage));
};

lazyLoadImages();

// Function to handle the intersection
const handleIntersectionImg = function (entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
    }
  });
};

// Lazy loading video

document.addEventListener('DOMContentLoaded', function () {
  const videoContainers = document.querySelectorAll('.video-container');

  function handleIntersection(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        console.log(entry.target);

        const img = entry.target.querySelector('img');
        const videoUrl = img.getAttribute('data-video');
        const video = document.createElement('video');

        video.src = videoUrl;
        video.controls = false;
        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.playsInline = true;
        video.preload = 'auto';
        video.classList.add('video');

        img.parentNode.replaceChild(video, img);
        observer.unobserve(entry.target);
      }
    });
  }

  const observer = new IntersectionObserver(handleIntersection, {
    rootMargin: '0px',
    threshold: 0.1,
  });

  videoContainers.forEach(container => {
    observer.observe(container);
  });
});

// CAROUSEL

const slider = function () {
  const slides = document.querySelectorAll('.slide');
  const btnLeft = document.querySelector('.slider__btn--left');
  const btnRight = document.querySelector('.slider__btn--right');
  let currentSlide = 0;
  const maxSlide = slides.length;
  const dotContainer = document.querySelector('.dots');

  // Functions
  const createDots = function () {
    slides.forEach(function (_, i) {
      dotContainer.insertAdjacentHTML(
        'beforeend',
        `<button class = "dots__dot dot" data-slide="${i}"></button>`
      );
    });
  };

  const activateDot = function (slide) {
    document
      .querySelectorAll('.dots__dot')
      .forEach(dot => dot.classList.remove('dots__dot--active'));

    document
      .querySelector(`.dots__dot[data-slide="${slide}"]`)
      .classList.add('dots__dot--active');
  };

  const goToSlide = function (slide) {
    slides.forEach(
      (s, i) => (s.style.transform = `translateX(${100 * (i - slide)}%)`)
    );
  };

  const nextSlide = function () {
    if (currentSlide === maxSlide - 1) {
      currentSlide = 0;
    } else {
      currentSlide++;
    }

    goToSlide(currentSlide);
    activateDot(currentSlide);
  };

  const init = function () {
    goToSlide(0);
    createDots();
    activateDot(0);
  };
  init();

  // Event handlers
  btnRight.addEventListener('click', nextSlide);

  const prevSlide = function () {
    if (currentSlide === 0) {
      currentSlide = maxSlide - 1;
    } else {
      currentSlide--;
    }
    goToSlide(currentSlide);
    activateDot(currentSlide);
  };

  btnLeft.addEventListener('click', prevSlide);

  document.addEventListener('keydown', function (e) {
    if (e.key === 'ArrowLeft') prevSlide();
    e.key === 'ArrowRight' && nextSlide();
  });

  dotContainer.addEventListener('click', function (e) {
    if (e.target.classList.contains('dots__dot')) {
      console.log('DOT');
      const { slide } = e.target.dataset;
      console.log(e.target);
      goToSlide(slide);
      activateDot(slide);
    }
  });
};
slider();

// Mobile navigation menu

btnNavEl.addEventListener('click', function () {
  navContainerEl.classList.toggle('nav-open');
  heroContainerEl.classList.toggle('nav-open');
});

const navLinksEl = document.querySelector('.nav-links');
const navLinkEl = document.querySelectorAll('.nav-link');

navLinksEl.addEventListener('click', function (e) {
  let isClickedInsideNavLinks = false;
  navLinkEl.forEach(link => {
    if (link.contains(e.target)) {
      isClickedInsideNavLinks = true;
    }
  });

  if (!isClickedInsideNavLinks) {
    navContainerEl.classList.toggle('nav-open');
    heroContainerEl.classList.toggle('nav-open');
  }
});
